import { faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import "./Header.css";
export const GlassicoIcon = () => {
  return (
    <div className="customGlass">
      <div className="logo-container">
        <img
          className="logo"
          src="pictures/black_logo.jpeg"
          alt="Custom Glass Professionals"
          width="100"
          height="100"
        />
      </div>
      <div
        style={{
          fontSize: "20px",
          fontWeight: "100",
          color: "#fcffff",
        }}
      >
        Custom Glass Professionals
      </div>
    </div>
  );
};

const Header: React.FC = () => {

  return (
    <div
      style={{
        background: "linear-gradient(to left, white, black 70%, black)",
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontFamily:
            "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
        }}
      >
        <GlassicoIcon />
        <div className="ContuctUs-header-container">
          <div
            className="headerContuctUs"
            style={{
              fontSize: "20px",
              fontWeight: "100",
              color: "#338686",
            }}
          >
            Contact Us Now:
          </div>
          <div
            className="headerContuctNumber"
            style={{
              fontSize: "20px",
              fontWeight: "100",
              margin: "10px",
            }}
          >
            +1 647 229 7327
          </div>
          <a href="tel:+16472297327">
            <div className="callUs">
              <FontAwesomeIcon
                icon={faPhoneVolume}
                beatFade
                size="2x"
                color="#3bb4ab"
              />
            </div>
          </a>
        </div>
      </div>

    </div>
  );
};

export default Header;
