import React from "react";
import "./Card.css";

import { ProgressBar } from "./ProgressBar";
import Number from "./AnimatedNumber";
const MyCard = () => {
  return (
    <div className="card">
      <div className="cardTop">WHY CHOOSE US?</div>
      {[
        "Integrity",
        "Quality",
        "Efficiency",
        "Innovation",
        "Service",
        "Upfront pricing",
        "Fast response",
        "Installation",
        "Satisfaction",
      ].map((val) => (
        <div key={val} className="card-item">
          <div
            style={{
              color: "#294b4a",
              fontSize: 18,
              fontWeight: 700,
              minWidth: 135,
              fontFamily:
                "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
            }}
          >
            {val}:{" "}
          </div>
          <ProgressBar />
          <div
            style={{
              textAlign: "center",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              color: "#27282a",
              minWidth: "70px",
              fontSize: "18px",
              fontFamily:
                "Cambria, Cochin, Georgia, Times, 'Times New Roman', serif",
            }}
          >
            <Number n={100} color="#27282a" />%
          </div>
        </div>
      ))}
      <div className="consultation">
        <h3>GET YOUR FREE PHONE CONSULTATION & PRICE ESTIMATION NOW!</h3>
      </div>
    </div>
  );
};

export default MyCard;
