import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Main from "./Main";
import ServiceCard from "./ServiceCard";
import AboutUs from "./AboutUs";
import { NotFound } from "./NotFound";
import Gallery from "./Gallery";

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/home" element={<Main />} />
            <Route path="/services" element={<ServiceCard />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;