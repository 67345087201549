import MyCard from "./Card";
import Images from "./Images";
import News from "./News";
import "./Main.css";
const Main = () => {

  return (
    <div
      style={{
        paddingTop: "20px",
      }}
    >
      <div className="mainPictures">
        <Images />
        <h1 className="mainText">
          Discover Expert Craftsmanship in Glass Shower Enclosures, Glass Railings, and Modern Glass Decor in Toronto
          <span>FREE PHONE CONSULTATION & PRICE ESTIMATION </span></h1>


      </div>
      <div className="content">
        <div className="page-container">
          <div className="Description">
            <p className="bolt"></p>
            Choose Glassico - Your Premier Glass Architectural Studio in Toronto serving the Greater Toronto Area (GTA), we
            redefine elegance and innovation in architectural glass design. Our
            commitment to excellence, paired with years of experience, makes us
            the top choice for those seeking a unique custom glass experience.
            <p className="bolt"> Unrivaled Expertise in Glass Design:</p> With a team of
            seasoned architects and designers, we bring our knowledge to every
            project. From intricate glass railings to bespoke glass
            installations, our expertise shines through.
            <p className="bolt">Customized Glass Solutions:</p> Your vision is our
            blueprint. We specialize in tailoring glass solutions to your unique
            requirements. Whether it's a new and shinny glass shower or a
            stunning glass facade, we turn your ideas into reality.
            <p className="bolt">Quality Craftsmanship:</p> Crafted with
            precision, our glass creations exemplify quality. We use
            cutting-edge technology and premium materials to ensure durability,
            functionality, and timeless beauty in every work.
            <p className="bolt">Innovative Designs:</p>Stay ahead of trends with
            our avant-garde designs. We blend artistic flair with modern
            functionality, offering designs that not only meet but exceed
            expectations. <p className="bolt">Client-Centric Approach:</p>
            Your satisfaction is our priority. We foster open communication,
            involve you in the creative process, and deliver results that
            surpass your aspirations.
            <p className="bolt">Sustainable Solutions:</p> Embrace eco-friendly
            designs without compromising aesthetics. Our commitment to
            sustainability is reflected in our use of energy-efficient materials
            and practices.
            <p className="bolt"> Timely Delivery:</p> We understand the
            importance of timelines. Our efficient project management ensures
            timely delivery without compromising the quality of our work.
          </div>
          <MyCard />
        </div>
      </div>
      <News />
    </div>
  );
};

export default Main;
