import React, { useState } from "react";
import "./Gallery.css";

interface GalleryItem {
  image: string;
  category: string;
}

const gallery: GalleryItem[] = [
  {
    image: "glass-railing-12mm-handrail-on-side-off-the-glass.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-standoff.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-railing-standoff-baseshoe.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-standoff-handrail-on-top.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railings-with-wood-handle.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-without-handrail.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-staircase-wall.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-wall-side-stringer-stairs.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-wood-railings.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-wood-railings-with-handle.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "outdoor-enterence.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "outdoor-railings-enterence.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "tempered-glass-standoff-railing-oak-handrail.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "tempered-glass-wall.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-standoof-hadrail-on-the-wall.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-partition-12mm-black-clamps.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-10mm.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-10mm-stainless.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-10mm-stainless-steel.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railing-10mm-deck.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-railings-with-wooden-handle.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "outdoor-railings.webp",
    category: "GLASS_RAILINGS",
  },
  {
    image: "glass-shower.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },
  {
    image: "glass-shower-90degrees.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },
  {
    image: "glass-shower-enclosure.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },
  {
    image: "glass-shower-gold-furniture.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },

  {
    image: "glass-shower-towelbar-chrome-finish-hinges.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },

  {
    image: "glass-wall-shower.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },
  {
    image: "gold-finish-hardware-glass-shower.webp",
    category: "CUSTOM_GLASS_SHOWERS",
  },

  {
    image: "glass-office-double-door.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-office-frosted-sticker.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-office-hydraulic-patch.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-office-hydraulic-patch-round-handle.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-office-pivots.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-office-with-frosted-sticker.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "glass-winecellar-black-finish-hardware.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "mirror-bevel.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "mirrors-master-bathroom.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },

  {
    image: "office-chrome-chanel.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "office-glass-door.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "office-glass-door-hydraulic-patch.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "winecellar.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "winecellar-double-glass-door.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "backsplash-6mm.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "backsplash-6mm-glass.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
  {
    image: "backsplash-eastgwillimbury.webp",
    category: "CUSTOM_GLASS_PRODUCTS",
  },
];

const Gallery: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");


  return (
    <div className="galleryPage">
      <h1 className="topic">
        Discover the exceptional works and design of Glassico through our
        gallery
      </h1>
      <p
        style={{
          minWidth: "200px",
          fontSize: "18px",
          lineHeight: "1.5",
          color: "#293241",
          justifyContent: "center",
          display: "flex",
          maxWidth: "700px",
        }}
      >
        From elegant glass railings to glass showers and custom glass products.
        Each image reflects our dedication to precision and innovation in
        architectural glass solutions. Imagine the transformative impact our
        work could have on your space.
      </p>
      <div className="buttonsArea">
        {[
          { text: "All glass products", category: "All" },
          { text: "Glass railings", category: "GLASS_RAILINGS" },
          { text: "Custom glass showers", category: "CUSTOM_GLASS_SHOWERS" },
          { text: "Custom glass products", category: "CUSTOM_GLASS_PRODUCTS" },
        ].map((f) => (
          <button
            key={f.category}
            className="customButton"
            onClick={() => setSelectedCategory(f.category)}
          >
            {f.text}
          </button>
        ))}
      </div>
      <div className="gallery-container">
        {gallery.map((g: GalleryItem, index: number) => (
          <div key={index} className="gallery-item">
            <img
              src={`./pictures/${g.image}`}
              alt="gallery"
              loading="lazy"
              className="serviceIcon"
              style={{
                display:
                  g.category === selectedCategory || selectedCategory === "All"
                    ? "block"
                    : "none",
              }}
              onClick={() =>
                window.open(
                  `./pictures/Image_full_size/${g.image}`,
                  "_blank",
                  "noopener, noreferrer"
                )
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;
