
// import { Link } from "react-router-dom";
import "./MenuBar.css";

import { Link, useLocation } from "react-router-dom";
const MenuBar: React.FC = () => {

    const location = useLocation();

    const isLinkActive = (to: string): boolean => {
        return location.pathname === to;
    };

    return (

        <div className="menubar">
            {/* <div className="bar active"> */}
            <ul className="menu">
                <li className={`link ${isLinkActive("/home") ? "active-link" : ""}`}>
                    <Link to="/home" style={{ color: "white" }}>
                        Home
                    </Link>
                </li>
                <li className={`link ${isLinkActive("/services") ? "active-link" : ""}`}>
                    <Link to="/services" style={{ color: "white" }}>
                        Services
                    </Link>
                </li>
                <li className={`link ${isLinkActive("/gallery") ? "active-link" : ""}`}>
                    <Link to="/gallery" style={{ color: "white" }}>
                        Gallery
                    </Link>
                </li>
                <li className={`link ${isLinkActive("/about") ? "active-link" : ""}`}>
                    <Link to="/about" style={{ color: "white" }}>
                        About Us
                    </Link>
                </li>


            </ul>
            {/* </div> */}
        </div>

    );
};

export default MenuBar;
