import * as React from "react";
import { ProgressIndicator } from "@fluentui/react/lib/ProgressIndicator";

const intervalDelay = 50;
const intervalIncrement = 0.05;

export const ProgressBar: React.FunctionComponent = () => {
  const [percentComplete, setPercentComplete] = React.useState(0);

  React.useEffect(() => {
    const id = setInterval(() => {
      setPercentComplete(intervalIncrement + percentComplete);
    }, intervalDelay);
    return () => {
      clearInterval(id);
    };
  });

  return (
    <div
      style={{ minWidth: "100px", maxWidth: "200px", width: "30%" }}
      onMouseEnter={() => setPercentComplete(0)}
    >
      <ProgressIndicator
        styles={{ progressBar: { background: "#3bb4ab" } }}
        barHeight={7}
        percentComplete={percentComplete}
      />
    </div>
  );
};
