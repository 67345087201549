import React, { useState } from "react";
import "./App.css";

import Footer from "./Footer";

import Header from "./Header";


import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import MenuBar from "./MenuBar";
const App: React.FC = () => {

  return (
    <div className="App">
      <Router>
        <Header />
        <MenuBar />
        <AppRoutes />
        <Footer />
      </Router>
    </div>
  );
};
export default App;
