export function initializeScrollAnimation(): void {
    const serviceCards = document.querySelectorAll<HTMLElement>(".serviceCards");

    function isElementInViewport(el: HTMLElement): boolean {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    function handleScroll(): void {
        serviceCards.forEach((card, index) => {
            if (isElementInViewport(card)) {
                setTimeout(() => {
                    card.classList.add("in-view");
                }, index * 50); // Adjust the delay as needed
            }
        });
    }

    // Initial check on page load
    handleScroll();

    // Check when scrolling
    window.addEventListener("scroll", handleScroll);
} 