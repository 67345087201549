import "./ServiceCard.css";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { IoCubeOutline } from "react-icons/io5";
import { TbCubeUnfolded } from "react-icons/tb";
import { BiShower } from "react-icons/bi";
import { VscMirror } from "react-icons/vsc";
import { MdOutlineTableBar } from "react-icons/md";
import { initializeScrollAnimation } from "./scrollAnimation";
import { useEffect } from "react";
const services = [
  {
    icon: <TbCubeUnfolded />,
    name: "GLASS RAILINGS",
    text: "We specialize in custom glass railing systems and glass balustrades for both residential and commercial projects. Whether you have an interior or exterior railing system, our experienced team can provide the right solutions. Let us team up with you – we can help you achieve the look and function you desire. ",
  },
  {
    icon: <BiShower />,
    name: "CUSTOM GLASS SHOWERS",
    text: "We make both, framed and frame-less custom showers, glass walls or glass-enclosures for the bathroom. Clear, sanded or decorative glass of any color or thickness can match every unique project of your dream bathroom. Over the years we have installed hundreds of custom shower and have a team of experienced professionals to help you. ",
  },
  {
    icon: <VscMirror />,
    name: "CUSTOM MIRRORS",
    text: "We make any size custom wall and vanity mirrors to fit any residential or commercial space. We can make any necessary aperture for lights, outlets or even plumbing fixtures to be installed through a mirror. Backlit mirrors or mirrors with custom wood framing are also available. Each custom mirror we design is handcrafted for your own space.",
  },
  {
    icon: <HiOutlineBuildingOffice />,
    name: "GLASS OFFICE PARTITIONS",
    text: "We specialize in custom glass office partitions. Glass office partitions are a modern solution to dividing space between employees in the workplace and are becoming more and more popular in contemporary offices worldwide. Frameless partitions are both sleek and elegant, adding a touch of style to the workplace.",
  },
  {
    icon: <MdOutlineTableBar />,
    name: "GLASS RECEPTION DESKS",
    text: "Well-designed, well-functional and well-installed glass reception desk will truly bring a contemporary style sensation to your office, so if you are attempting to convince people you are trending and up with the times, this kind of desk does a far better job of that than a wooden one.",
  },
  {
    icon: <IoCubeOutline />,
    name: "CUSTOM GLASS PRODUCTS",
    text: "Glassico professionals fabricate and install all kinds of high quality,custom glass products such as backsplashes, table tops, glass shelves, dry erase boards and more... We can fabricate a large variety of colors to fit your needs and even set you up with custom patterns.",
  },
];

const ServiceCard = () => {
  useEffect(() => {
    initializeScrollAnimation();
  }, []);
  return (
    <section>
      <h1 className="topic">
        Elevate Spaces with Expertly Crafted Glass Solutions in Toronto
        <span>Specializing in Glass Railings, Custom Showers, Mirrors, and Office Partitions</span>
      </h1>

      <div className="servicePage">

        {services.map((s) => (
          <div className="cardArea" key={s.name}>
            <div className="serviceCards">
              <div
                className="Icon"
                style={{
                  backgroundColor: "#f4f4f4",
                  borderRadius: "50%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "80px",
                  height: "80px",
                  color: "#3bb4ab",
                  fontSize: "50px",
                }}
              >
                {s.icon}
              </div>
              <h2>{s.name}</h2>
              <p>{s.text}</p>
            </div>
          </div>
        ))}
      </div></section>
  );
};

export default ServiceCard;
