import { useSpring, animated } from "react-spring";
import "./AnimatedNumber.css";

interface NumberProps {
  n: number;
  color?: string; // You can accept a color prop
}

const Number: React.FC<NumberProps> = ({ n, color = "#3bb4ab" }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: n,
    delay: 1000,
    config: { mass: 1, tension: 10, friction: 10 },
  });

  return (
    <animated.div style={{ color }}>
      {number.to((n) => n.toFixed(0))}
    </animated.div>
  );
};

export default Number;
