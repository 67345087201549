import "./Footer.css";
import { GlassicoIcon } from "./Header";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { RiMailFill } from "react-icons/ri";

const Footer: React.FC = () => {
  return (
    <div className="footer">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <GlassicoIcon />
        <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          <div>Contact Us:</div>
          <div>glassicotoronto@gmail.com</div>
          <div>+16472297327</div>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <a
              href="https://wa.me/+16472297327"
              target="_blank"
              rel="noreferrer"
            >
              <FaWhatsappSquare
                style={{ width: "30px", height: "30px", color: "#3bb4ab" }}
              />
            </a>
            <a
              href="https://www.facebook.com/glassicotoronto"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebookSquare
                style={{ width: "30px", height: "30px", color: "#3bb4ab" }}
              />
            </a>

            <a
              href="mailto:glassicotoronto@gmail.com"
              rel="noreferrer"
              target="_blank"
            >
              <RiMailFill
                style={{ width: "30px", height: "30px", color: "#3bb4ab" }}
              />
            </a>
          </div>
        </div>
      </div>
      <p>Copyright Glassico © 2023. All rights reserved.</p>
      <div className="developer-credit" style={{ color: "white", textDecoration: "none" }}>
        Developed and managed by <a href="https://www.lilays.com" target="_blank" rel="noreferrer" style={{ color: "white" }}>Lilays</a>
      </div>
    </div>
  );
};

export default Footer;
