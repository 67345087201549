import React, { useState, useEffect } from "react";
import "./Images.css";

const imageList = [
  "./pictures/homepage/decorative-wall-mirror.webp",
  "./pictures/homepage/modern-panoramic-rails.webp",
  "./pictures/homepage/modern-rails.webp",
  "./pictures/homepage/frameless-glass-shower.webp",
];

const Images: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevImage) => (prevImage + 1) % imageList.length);
    }, 4500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="imageContainer">
      {imageList.map((image, index) => {
        return (
          <div className="imageArea" key={index}>
            <img
              className="imageSize"
              style={{
                display:
                  imageList[currentImageIndex] === image ? "block" : "none",
              }}
              src={image}
              alt="Glass work example"
              loading="lazy"
            />
          </div>
        );
      })}

      <div className="dot-container">
        {imageList.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentImageIndex === index ? "active" : ""}`}
            onClick={() => setCurrentImageIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Images;
