import React from "react";
import "./News.css";
const News: React.FC = () => {
  const newsImages = [
    {
      image: "pictures/glass-shower-gold-furniture.webp",
      discription: "Stylish designs and options for shower enclosures",
      text: "Selecting the right shower enclosure and its design significantly influences the overall appearance of your bathroom. It's crucial to carefully consider the style, materials, and other elements. Your choice should not only reflect your personal style but also align with the functional aspects of the bathroom. Therefore, ensure that the chosen shower enclosure complements both your aesthetic preferences and the practical needs of the space.",
    },
    {
      image: "pictures/glass-office-hydraulic-patch.webp",
      discription: "The benefits of glass office partitions",
      text: "Incorporating floor-to-ceiling glass partitions in an office setting offers a multitude of benefits. Beyond the limitations of ordinary windows and doors, these partitions maximize the infusion of natural light, creating a bright and open space. The strategic use of glass promotes a visually appealing ambiance, contributing to employee well-being and productivity. Beyond aesthetics, the partitions facilitate transparency, connectivity, and collaboration among team members. This design choice not only aligns with modern office trends but also enhances the overall atmosphere and functionality of the workspace.",
    },
    {
      image: "pictures/mirrors-master-bathroom.webp",
      discription: "Add style and depth to your home with mirrored walls",
      text: "Just like mirrors, mirrored walls have the ability to make a room seem bigger. They almost double the space by replicating what’s already existent. The use of mirrored walls goes beyond mere reflection, it is a design strategy that creates an illusion of spaciousness, maximizes light, and adds a touch of elegance to interior spaces. Whether in residential or commercial settings, the transformative impact of mirrored walls makes them a valuable asset in the repertoire of interior designers.",
    },
    {
      image: "pictures/backsplash-6mm.webp",
      discription: "Cool ways to update a kitchen with a glass backsplash",
      text: "Ceramic tiles, bricks, mosaic stones, wood, metal, marble and even simple paint or wallpaper are all trendy options when it comes to kitchen backsplashes. Hovever, glass tiles offer a sleek and modern look to the kitchen. They come in a variety of colors, shapes, and finishes, allowing for creative and customizable designs. The reflective surface of glass tiles also helps in brightening up the kitchen space.",
    },
    {
      image: "pictures/railings.webp",
      discription:
        "Glass staircase designs with a graceful impact on the overall decor",
      text: "Glass staircase designs make a graceful impact on the overall decor by blending elegance, transparency, and contemporary aesthetics. These designs not only serve their functional purpose but also contribute to the creation of a visually stunning and cohesive interior environment. Whether in residential homes, commercial spaces, or public buildings, glass staircases leave an indelible mark on the overall decor, combining beauty with functionality in a seamless manner.",
    },
    {
      image: "pictures/office-chrome-chanel.webp",
      discription:
        "Making the most of small office spaces with glass partitioning",
      text: "Do you find that your office is small, overcrowded and just not how you hoped it would be? Partitioning a small office space with glass offers a myriad of benefits, combining functionality with aesthetics to make the most of limited square footage. Glass partitioning provides a modern and open feel while addressing the need for delineated work areas. ",
    },
  ];

  return (
    <div>
      <h2
        className="topic"
        style={{
          color: "#355b5c",
          paddingTop: "20px",
        }}
      >
        TIPS & TRICKS
      </h2>
      <div className="news-container">
        {newsImages.map((n) => (
          <div className="newsCard" key={n.image}>
            <div>
              <img src={n.image} alt="Icon" className="newsImage" />
            </div>
            <div className="newsDescription">{n.discription}</div>
            <div className="newsText">{n.text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default News;
