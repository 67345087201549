import Number from "./AnimatedNumber";
import "./AnimatedNumber.css";
import "./AboutUs.css";

import { Rating, RatingSize, initializeIcons } from "@fluentui/react";
initializeIcons();

const reviews = [
  {
    name: "Anthony D.",
    address: "Newmarket, ON",
    text: "We wanted to thank you and everyone at Glassico for the excellent work you did in our office. It was absolutely the right decision. Everyone who worked on the partitions was  professional and efficient, and the quality of the work far exceeded our expectations. Please feel free to share and pass on our contact information if you need a reference.",
  },
  {
    name: "Julia K",
    address: "Richmond Hill, ON",
    text: "Simply the best glass contractor in Toronto area. It wasn’t easy to pick a contractor to remodel our indoor railing system. The crew was clean, professional, on time, and didn’t play games with adding extra costs. The end result was rather impressive. highly recommend this company to anyone looking to get professional glass railing work done on their home.",
  },
  {
    name: "Adam T",
    address: "Maple, ON",
    text: "I wanted to let you know how pleased I am with the work done at my home on Kavala Str. The job was so well done by wonderful people. They were efficient, diligent and hardworking. The professionalism expended by you and your employees reflects well on your company. It was pleasant doing business with Glassico!",
  },
  {
    name: "Marry J",
    address: "Toronto, ON",
    text: "They have been working patiently and diligently with my husband and me to install custom glass shower and mirror. I must say, given my budget and their reasonable price, they managed to provide us with exactly what we’ve asked of them. The Glassico team have done an excellent job. We are completely satisfied.",
  },
];
const facts = [
  { fact: "Showers Projects Done", count: 284 },
  { fact: "Railings Projects Done", count: 248 },
  { fact: "Mirrors Projects Done", count: 127 },
  { fact: "Other Projects Done", count: 348 },
];
const AboutUs = () => {
  return (
    <div className="AboutUsPage">
      <div className="AboutUs">
        <div className="skills">
          <h2 className="topic">OUR SKILLS</h2>
          <p style={{ maxWidth: "700px", minWidth: "300px" }}>
            We specialize in crafting beautiful glass shower spaces,creating elegant glass railings and modern glass decor. Our expertise extends to enhancing both indoor and outdoor areas with our expert glass installations. At Glassico, our commitment is to deliver top-notch solutions at the best possible price. We take pride in offering choices that are not only cost-effective but also ensure excellence in our work. Trust Glassico for all your glass needs, where quality meets the best price.
          </p>
        </div>

        <div className="facts">
          <h1 className="topic">OUR FACTS</h1>
          <div className="factsCard">
            {facts.map((f) => (
              <div className="factsNumbers" key={f.fact}>
                <Number n={f.count} />
                <div>{f.fact}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className=" reviewArea"
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <h2 className="topic"> WHAT OUR CLIENTS SAY</h2>

        <div className="rating">
          {reviews.map((r) => (
            <div className="reviewCards" key={r.name}>
              <div className="stars">
                <Rating
                  readOnly
                  styles={{ ratingStarFront: { color: "#F5B041" } }}
                  size={RatingSize.Large}
                  rating={5}
                  className="custom-rating"
                />
              </div>
              <div className="Pborders">
                <p>"{r.text}"</p>
              </div>
              <div className="name">{r.name}</div>
              <div className="address">{r.address}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
export default AboutUs;
